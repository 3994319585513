import { ContentByTypeQueryVariables } from '@/__generated__/graphql';
import { ENTRIES_BY_TYPE_QUERY } from '@/gql/queries/entries.gql';
import { EntryTypename, parseEntries } from '@/lib/parsers/entries';
import { useQuery } from '@apollo/client';

export const useLatestEntries = <TName extends EntryTypename>(
  variables: ContentByTypeQueryVariables,
  typeNames: TName[] | TName = []
) => {
  const { data, loading, error } = useQuery(ENTRIES_BY_TYPE_QUERY, {
    variables,
  });

  if (error) console.warn(error.message);

  const result = parseEntries(data?.entries, typeNames);

  return {
    result,
    loading,
    error,
  };
};
